$(document).on('ready turbolinks:load', function() {

  function readURL(input) {
    if (input.files && input.files[0]) {
      var reader = new FileReader();

      reader.onload = function(e) {
        $('#img-preview').attr('src', e.target.result);
        $('#img-preview').show();
      }

      reader.readAsDataURL(input.files[0]); // convert to base64 string
    }
  }

  $("#picture_img").change(function() {
    $('#img-preview').hide();
    readURL(this);
  });

  $('#img-preview').hide();

});